const features = {
  theShop: 'theShop',
  candleSubscription: 'candleSubscription',
  addQuebecToCanada: 'addQuebecToCanada',
  defaultCoupon50: 'defaultCoupon50', // PF-892
  theEdit: 'theEdit', // PF-1242
  fillYourQueueSweepstakes: 'fillYourQueueSweepstakes', // PF-1668
  optInPopups: 'optInPopups', // PF-1279
  cotyNYNJ: 'cotyNYNJ', // PF-1411
  strapiBanners: 'strapiBanners', // PF-1464
  giftHub: 'giftHub', // EP-1935
  giftHubNotification: 'giftHubNotification', // EP-1989
  holiday22Sub60: 'holiday22Sub60', // EP-1916
  holiday22CardUpdate60: 'holiday22CardUpdate60', // EP-1925
  holiday22Resubscribe60: 'holiday22Resubscribe60', // EP-1924
  giftSubscriptionOffer: 'giftSubscriptionOffer', // PF-1570
  // Website takeovers, `featuredBrand` prefix for brand page, `websiteTakeover` prefix for entry points
  strapiFeaturedBrand: 'strapiFeaturedBrand', // PF-1525
  strapiWebsiteTakeover: 'strapiWebsiteTakeover', // PF-1525
  scentbirdPodcastEntryPoints: 'scentbirdPodcastEntryPoints', // PF-1682
  scentHoroscope: 'scentHoroscope', // PF-1626
  strapiDynamicPage: 'strapiDynamicPage', // PF-1657
  limitedDrops: 'limitedDrops', // PF-1695
  coarPhysicalRebrand: 'coarPhysicalRebrand', // PF-1751
  holidayMothersDay: 'holidayMothersDay', // PF-1768
  driftEcommerce: 'driftEcommerce', // PF-1715
  driftEcommercePromo: 'driftEcommercePromo', // PF-1770
  driftEcommercePromoModal: 'driftEcommercePromo', // PF-1770
  strapiMainPageBanners: 'strapiMainPageBanners', // PF-1865
  holidayFathersDay: 'holidayFathersDay', // PF-1850
  budgieChatAssistance: 'budgieChatAssistance', // PF-1868
  strapiPromoCard: 'strapiPromoCard', // PF-1697
  driftSubscription: 'driftSubscription', // PF-1759
  driftSubscriptionLaunchPromo: 'driftSubscriptionLaunchPromo', // PF-1759
  ecommerceTwoZero: 'ecommerceTwoZero', // PF-1999
  saleEcommerceFilter: 'saleEcommerceFilter', // PF-1999
  giftsUnder50Page: 'giftsUnder50Page', // PF-2076
  mobileScentbirdSelectFilter: 'mobileScentbirdSelectFilter',

  // This section will only be used for growth book features
  faro: 'faro', // EP-2057
  sri: 'web-sri', // CRO-254,
  cheqAi: 'web-cheq-ai', // EP-2404
  noibu: 'web-noibu',
  jscrambler: 'jscrambler',
  rudderstack: 'web-rudderstack', // EP-2881
  cacheForBots: 'cache-for-bots', // EP-1962
  hideCancelSubscriptionButton: 'hide-cancel-subscription-button', // EP-1424
  hideCasesReviews: 'web-hide-cases-reviews', // EP-2145
  shopSamples: 'web-shop-samples', // EP-2037
  travelSizeFragrances: 'web-travel-size-fragrances', // EP-2099
  coarShopPage: 'web-coar-shop-page', // EP-2296
  applyPayWeb: 'apple-pay-web', // EP-2617
  attentiveCollectPhoneNumber: 'web-attentive-collect-phone-number', // EP-2021
  giftCode50PromoCard: 'gift-code-50-promo-card', // PF-2292
  giftHubHeaderBanner: 'gift-hub-header-banner', // PF-2306
  headerBannerVisibleOnGiftHub: 'header-banner-visible-on-gift-hub', // PF-2306
  strapiCoarPage: 'strapi-coar-page', // PF-2301 - candidate for deletion
  strapiHoroscopePage: 'strapi-horoscope-page', // PF-2304
  strapiCollaborationPage: 'strapi-collaboration-page', // PF-2404
  strapiFragranceFamiliesPage: 'strapi-fragrance-families-page', // PF-2404
  applePayPaymentMethod: 'apple-pay-payment-method', // EP-2674
  holidayBanner: 'holiday-banner', // EP-2697
  twelveDaysPage: 'twelve-days-page', // PF-2093
  bfcmNotification: 'bfcm-notification', // PF-2249
  giftSubscriptionNotification: 'gift-subscription-notification', // PF-2249
  strapiCareersPage: 'strapi-careers-page', // PF-2278
  giftHubValentinesNotificationMessage: 'gift-hub-valentines-notification-message', // PF-2259
  paymentWebsockets: 'payment-websockets', // EP-2725
  buy5pay4: 'buy-5-pay-4', // EP-2570
  gwpTopBar: 'gwp-top-bar', // PF-2379
  constructorRecommendations: 'contructor-recommendations',
  palmRoyaleCollaboration: 'palm-royale-collaboration', // PF-2401
  ellimanPerksPage: 'elliman-perks-page', // 2770
  randomHouseCollaboration: 'random-house-collaboration', // PF-2403
  mothersDayPostShippingCutOff: 'mothers-day-post-shipping-cut-off', // PF-2449
  fathersDayPostShippingCutOff: 'fathers-day-post-shipping-cut-off', // PF-2512
  buyQueuePerkCard: 'buy-queue-perk-card', // PF-2488
  brandPageWithFSBTab: 'brand-page-with-fsb-tab', // PF-2464
  gwpSpendThresholdDynamicText: 'gwp-spend-threshold-dynamic-text', // PF-2395
  giftHubEvergreen: 'gift-hub-evergreen', // PF-2555
  strapiPdpFragments: 'strapi-pdp-fragments', // PF-2498
  aaHowItWorks: 'how-it-works', // CRO-451
  aaBurgerIcon: 'burger-icon', // CRO-451
  mansGroomingTopBar: 'mans-grooming-top-bar', // PF-2539
  bacheloretteCollaboration: 'bachelorette-collaboration', // PF-2582
  bacheloretteCollaborationAfterJuly8: 'bachelorette-collaboration-after-july-8', // PF-2632
  weddingCollaboration: 'wedding-collaboration', // EP-2881
  constructorAutosuggestBrands: 'constructor-autosuggest-brands', // PF-2663
  constructorAutosuggestNotes: 'constructor-autosuggest-notes', // PF-2663
  theSummerEdit: 'the-summer-edit', // PF-2661
  accessPerksPage: 'access-perks-page', // PF-2936
  membershipPrice: 'membership-price', // PF-2959
  aiSummary: 'ai-summary', // PF-2655
  aiSummaryLandingHow: 'ai-summary-landing-how', // PF-2788
  aiSummaryLandingModule: 'ai-summary-landing-module', // PF-2788
  aiSummaryPopup: 'ai-summary-popup', // PF-2788
  aiSummaryNotification: 'ai-summary-notification', // PF-2788
  aiSummaryCards: 'ai-summary-cards', // PF-2789
  aiSummaryPdpHero: 'ai-summary-pdp-hero', // PF-2790
  aiSummaryPdpReviews: 'ai-summary-pdp-reviews', // PF-2790
  flowers1800Collaboration: 'flowers-1-800-collaboration', // PF-2719
  marvelCollaborationNav: 'marvel-collaboration-nav', // PF-2771
  marvelCollaborationAltNav: 'marvel-collaboration-alt-nav', // PF-2771
  scentbirdSelectProductFeed: 'scentbird-select-product-feed', // PF-2816
  strapiCollaborationQuiz: 'strapi-collaboration-quiz', // PF-2748
  marvelCollaborationModal: 'marvel-collaboration-modal', // PF-2718
  strawberryShortcakeModal: 'strawberry-shortcake-modal', // EP-2980
  vipSubscriberSale: 'vip-subscriber-sale', // PF-2840
  alwaysAddLEP: 'web-always-add-lep',
  addonSamples: 'addon-samples', // PF-2825
  addonSamplesEntrypointsBanner: 'addon-samples-entrypoints-banner', // PF-2835
  candleSale: 'candle-sale', // PF-2841
  caseUpsellAddToCart: 'web-case-upsell-add-to-cart', // EP-2285
  caseUpsellAddToQueue: 'web-case-upsell-add-to-queue', // EP-2286
  caseUpsellHeretic: 'web-case-upsell-heretic', // EP-2287
  caseUpsellStrawberryShortcake: 'web-case-upsell-strawberry-shortcake', // EP-3037
  miniBottlesPage: 'web-mini-bottles', // EP-3028
  miniBottlesPopup: 'web-mini-bottles-popup', //EP-3030
  fullAddressToRecurly: 'web-full-address-to-recurly', // EP-3033
  strawberryShortcakeBanner20mlPage: 'web-strawberry-shortcake-banner-20-ml', // EP-3030
  constructorRecommendedForYou: 'constructor-recommended-for-you', // PF-2901
  digitalFragranceAwards: 'digital-fragrance-awards', // PF-2902
  constructorRecommendationsSubscribed: 'constructor-recommendations-subscribed', // PF-2903
  constructorRecommendationsActiveLead: 'constructor-recommendations-active-lead', // PF-2911
  theShopRootPage: 'the-shop-root-page', // PF-2882
  termsAndPrivacyStrapiPages: 'terms-and-privacy-strapi-pages', // EP-3064
  theEditHoliday: 'the-edit-holiday', // PF-2661
  theEditValentinesDay: 'the-edit-valentines-day', // PF-3047'
  dynamicPaymentConfigEnabled: 'dynamic-payment-config-enabled', // EP-3080
  constructorCatalogue: 'constructor-catalogue', // PF-2975
  digitalFragranceAwardWinners: 'digital-fragrance-award-winners', // PF-2984
  blackFridaySale: 'black-friday-sale', // PF-2986
  gsPostCutoffEntryPoints: 'gs-post-cutoff-entry-points', // PF-3008
  valentinesHeaderBannerGgFocused: 'valentines-header-banner-gg-focused', // PF-3063
  valentinesHeaderBannerUpTo60Off: 'valentines-header-banner-up-to-60-off', // PF-3063
  valentinesHeaderBannerGiftSubPush: 'valentines-header-banner-gift-sub-push', // PF-3063
  constructorRecentlyViewed: 'constructor-recently-viewed', // PF-3005
  fomArchive: 'fom-archive-page', // PF-3082
  multiplePlansSelection: 'multiple-plans-selection', // CRO-608
  okCupidCollaboration: 'ok-cupid-collaboration', // PF-3108,
  queuePageRudderStack: 'queue-page-rudder-stack', // AA experiment
  aiQuickScentMatch: 'ai-quick-scent-match', // PF-2896
  worldOfFragrance: 'world-of-fragrance', // PF-2896
  worldOfFragranceTopBar: 'world-of-fragrance-top-banner', // PF-3189
  strapiCarouselBanners: 'strapi-carousel-banners', // PF-2557
  giftSubscriptionEntrypoints: 'web-gift-subscription-entrypoints', // EP-3247
  strapiDynamicModal: 'strapi-dynamic-modal', // PF-3220
  strapiGwpFragments: 'strapi-gwp-fragments', // PF-3221
  selectCampaignLabels: 'select-campaign-labels', // PF-3219
  selectCampaignCatalogueFilters: 'select-campaign-catalogue-filters', // PF-3219
  grossePointeCollaboration: 'grosse-pointe-collaboration', // PF-3251,
  giftCardsEntrypoints: 'gift-cards-entrypoints', // PF-3238
  capsuleCollections: 'capsule-collections', // PF-3269,
  selectCampaignPremiumNav: 'select-campaign-premium-nav', // PF-3223
  dynamicPageSections: 'dynamic-page-sections', // PF-3222
  strapiLocalization: 'strapi-localization', // PF-3099
  strapiLocalizationPage: 'strapi-localization-page', // PF-3275
  strapiLocalizationWsto: 'strapi-localization-wsto', // PF-2702
  selectCampaignPdpFsbLink: 'select-campaign-pdp-fsb-link', // PF-3278
  strapiLocalizationBrand: 'strapi-localization-brand', // PF-2701
  fragranceTrends2025Nav: 'fragrance-trends-2025-nav', // PF-3291
  // End of growth book features
} as const


export type FeatureName = typeof features[keyof typeof features]

export default features
